<script setup>
import { state } from '@/store/store.js';
import LeadDetails from '@/components/MyLeads/LeadDetails.vue'; 
</script>

<script>
	export default {
		name: 'MyLeads',
		components: {},
		data() {
			return {
				leads: [],
				leadData: {},
				totalPages: 1,
				currentPage: 1,
				tableState: {
					rows: [],
					rowsPrePagination: [],
					selectedRows: []
				},
				tableFilters: {
                    status: { value: '', custom: this.statusFilter },
                    type: { value: '', keys: ['status'], exact: true },
                    county: { value: '', keys: ['county'] },
                    state: { value: '', keys: ['state'] },
					name: { value: '', keys: ['fullName'] }
                },
				rowsInVewSelected: false,
				allRowsSelected: false
			};
		},
		async mounted() {
			state.currentView = 'my-leads';
			let leads = await state.api.call("getMyLeads");
			for (let lead of leads) {
				if (state.user.rid === lead.owner1) {
					lead.sortDate = lead.owner1Purchase;
				}else if (state.user.rid === lead.owner2) {
					lead.sortDate = lead.owner2Purchase;
				}else if (state.user.rid === lead.owner3) {
					lead.sortDate = lead.owner3Purchase;
				}else {
					lead.sortDate = lead.dateOfPurchase;
				}
			}
			this.leads = leads;
			// console.log(this.leads);

			//Load Braintree SDK
			let btscripts = [
				"https://js.braintreegateway.com/web/3.111.0/js/client.min.js",
				"https://js.braintreegateway.com/web/3.111.0/js/hosted-fields.min.js",
				"https://js.braintreegateway.com/web/3.111.0/js/data-collector.min.js"
			];
			btscripts.map(script => {
				let ds = document.createElement('script');
				ds.setAttribute('src', script);
				document.head.appendChild(ds);
			});
		},
		methods: {
			toggleRowsInView() {
				this.rowsInVewSelected ? 
				(this.$refs.myLeadsTable.deselectAll(), this.rowsInVewSelected = false, this.allRowsSelected = false) :
				(this.$refs.myLeadsTable.selectRows(this.tableState.rows), this.rowsInVewSelected = true, (this.tableState.rowsPrePagination.length === this.tableState.selectedRows.length ? this.allRowsSelected = true : this.allRowsSelected = false));
			},
			selectAllRows() {
				this.$refs.myLeadsTable.selectAll();
				this.allRowsSelected = true;
			},
			statusFilter(value, lead) {
                let sold = lead.isSold,
                    val = value.toLowerCase().trim();
                // console.log(val);
                if (sold && 'sold'.includes(val)) { //Sold
                    return true;
                }else if (!sold && 'active'.includes(val)) { //Active
                    return true;
                }
                return false;
            },
			tableRowClicked() {
				setTimeout(() => {
					if (this.tableState.rowsPrePagination.length === this.tableState.selectedRows.length) {
						this.allRowsSelected = true;
						this.rowsInVewSelected = true;
					} else {
						this.allRowsSelected = false;
						this.rowsInVewSelected = false;
					}
				}, 100);
			},
			filterToggle(event) {
                event.stopPropagation();
                event.target.closest('th').classList.toggle('-filter');
            },
			leadStatus(lead) {
                if (lead.isSold) {
                    return "Sold";
                }else {
                    return "Active";
                }
            },
			displayLeadInfo(leadId) {
				this.leadData = this.leads.find(lead => lead.rid === leadId);
				state.leadsDetails.toggle();
			},
			countSelectedLeadsByStatus() {
				const selectedRows = this.tableState.selectedRows;
				const statusCounts = {};

				selectedRows.forEach(lead => {
					if (!statusCounts[lead.status]) {
						statusCounts[lead.status] = 1;
					} else {
						statusCounts[lead.status]++;
					}
				});

				let formattedText = "";

				for (let status in statusCounts) {
					formattedText += `${statusCounts[status]} ${status}, `;
				}
				// Remove trailing commas and any trailing whitespace with REGEX
				// https://stackoverflow.com/questions/17720264/remove-last-comma-and-possible-whitespaces-after-the-last-comma-from-the-end-o
				return formattedText.replace(/,\s*$/, "");
			},
			exportDetailsToCSV() {
				// Generate CSV
				let csvContent = "Name,Address,Home Phone,Work Phone,Cell Phone,Age,Spouse Age,Lendershot,Mortshort,Batch No.,Record ID\n";
				for (let lead of this.tableState.selectedRows) {
					csvContent += `${lead.fullName},"${lead.address}",${lead.homePhone},${lead.workPhone},${lead.cellPhone},${lead.age},${lead.spouseAge},${lead.lenderShort},${lead.mortShort},${lead.batchNo},${lead.rid}\n`;
				}

				var file = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
				if (window.navigator.msSaveOrOpenBlob) // IE10+
					window.navigator.msSaveOrOpenBlob(file, "multi-leads-details.csv");
				else { // Others
					var a = document.createElement("a"),
					url = URL.createObjectURL(file);
					a.href = url;
					a.download = "multi-leads-details.csv";
					document.body.appendChild(a);
					a.click();
					setTimeout(function() {
						document.body.removeChild(a);
						window.URL.revokeObjectURL(url);  
					}, 0); 
				}
			},
			downloadPDF() {
				let selIDs = this.tableState.selectedRows.map(lead => lead.rid);
				let pdf = window.open(window.location.origin.split(":80")[0] + "/api/getPDF?rid=" + selIDs.toString() + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH', '_blank');
				return pdf;
			},
			printDetails() {
				let pdf = this.downloadPDF();
				pdf.print();
			},
			selectedRowsSold() {
				let status = false;
				for (let lead of this.tableState.selectedRows) {
					if (lead.isSold) {
						status = true;
					}else {
						status = false;
						break;
					}
				}
				return status;
			},
			async setLeadStatus() {
				let status = this.selectedRowsSold() ? "Active" : "Sold";
				let response = await state.api.call("setLeadStatus", {leadIDs: this.tableState.selectedRows.map(l => l.rid), status: status, userID: state.user.rid});

				this.updateLeadsStatus(response.details);
			},
			updateLeadsStatus(leads) {
				let uLeadKeys = Object.keys(leads).map(e => parseInt(e));
				// console.log(response);
				for (let i = 0; i < this.leads.length; i++) {
					let lead = this.leads[i];
					if (uLeadKeys.includes(lead.rid)) {
						let status = leads[lead.rid].split('|');
						if (status[0] === 'Sold') {
							this.leads[i].isSold = true;
							if (status[1]) {
								this.leads[i].annualPremium = status[1];
							}
						}else {
							this.leads[i].isSold = false;
						}
					}
				}
			},
			updateLeadStatus(data) {
				let leads = {};
				if (data.annualPremium) {
					leads[data.rid] = `${data.status}|${data.annualPremium}`;
				}else {
					leads[data.rid] = data.status;
				}
				this.updateLeadsStatus(leads);
			},
			updateBurnStatus(data) {
				// console.log(response);
				for (let i = 0; i < this.leads.length; i++) {
					let lead = this.leads[i];
					if (lead.rid === data.rid) {
						this.leads[i].burned = data.burned;
						break;
					}
				}
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="leads.length && !state.isAPIProcessing">
		<VTable :data="leads" ref="myLeadsTable" :page-size="20" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" selectionMode="multiple" @stateChanged="tableState = $event" :selectOnClick="false" sortIconPosition="before" :filters="tableFilters">
			<template #head>
				<tr>
					<th class="checkbox-wrapper">
						<input type="checkbox" :checked="rowsInVewSelected" @change="toggleRowsInView()" />
					</th>
					<VTh :sortKey="leadStatus" class="_status">
                        Status
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="tableFilters.status.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
					<VTh sortKey="fullName">
						Lead Full Name
						<button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="tableFilters.name.value" @click="(event) => event.stopPropagation()" />
					</VTh>
					<VTh sortKey="state">
						State
						<button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="tableFilters.state.value" @click="(event) => event.stopPropagation()" />
					</VTh>
					<VTh sortKey="county">
						County
						<button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="tableFilters.county.value" @click="(event) => event.stopPropagation()" />
					</VTh>
					<VTh sortKey="status">
						Lead Type
						<button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="tableFilters.type.value" @click="(event) => event.stopPropagation()" />
					</VTh>
					<VTh sortKey="sortDate" defaultSort="desc">Purchase Date</VTh>
				</tr>
				<tr class="-span-full" v-if="rowsInVewSelected">
					<th v-if="!allRowsSelected" class="-clickable" @click="selectAllRows()">You have&nbsp;<strong style="font-weight: 800;">{{ tableState.selectedRows.length }}</strong>&nbsp;rows currently selected.&nbsp;<span style="text-decoration: underline;font-weight: 800;">Select All {{ tableState.rowsPrePagination.length }} Rows</span></th>
					<th v-else style="background-color:#0f960f;color:#fff;">All {{ tableState.selectedRows.length }} Rows Selected</th>
				</tr>
			</template>
			<template #body="{rows}">
				<VTr v-for="lead in rows" :key="lead.rid" :row="lead" v-slot="{ isSelected, toggle}">
					<td class="checkbox-wrapper">
						<input type="checkbox" :checked="isSelected" @change="toggle" @click="tableRowClicked()"/>
					</td>
					<td class="_status -sold" data-title="Status" v-if="lead.isSold"><span>Sold</span></td>
					<td class="_status -pending" data-title="Status" v-else><span>Active</span></td>
					<td class="_name" data-title="Lead Full Name" @click="displayLeadInfo(lead.rid)">{{ lead.fullName }}</td>
					<td data-title="State">{{ lead.state }}</td>
					<td data-title="County">{{ lead.county }}</td>
					<td data-title="Lead Type">{{ lead.status }}</td>
					<td data-title="Purchase Date" v-if="state.user.rid === lead.owner1">{{ state.unixToDate(lead.owner1Purchase) }}</td>
					<td data-title="Purchase Date" v-else-if="state.user.rid === lead.owner2">{{ state.unixToDate(lead.owner2Purchase) }}</td>
					<td data-title="Purchase Date" v-else-if="state.user.rid === lead.owner3">{{ state.unixToDate(lead.owner3Purchase) }}</td>
					<td data-title="Purchase Date" v-else>{{ state.unixToDate(lead.dateOfPurchase) }}</td>
				</VTr>
			</template>
		</VTable>
		<VTPagination
			v-model:currentPage="currentPage"
			:total-pages="totalPages"
			:boundary-links="true"
		/>
		</div>
		<div v-else-if="!leads.length && !state.isAPIProcessing" id="no-leads">
			<img alt="alert-triangle" src="@/assets/alert-triangle.svg">
			<span>You don't have any leads yet. Please go to the <span class="link-text" @click="$router.push('/purchase-lead')">Purchase Lead</span> or <span class="link-text" @click="$router.push('/standing-lead-order')">Standing Lead Order</span> section to buy the leads.</span>
		</div>
		<div class="footer" v-if="tableState.selectedRows.length">
			<div class="selected-list">
				<span class="selected-count">{{ tableState.selectedRows.length }} records selected</span>
				<span class="selected-text">{{ countSelectedLeadsByStatus() }}</span>
			</div>

			<!-- Only display 'Mark as Sold' button if one lead is selected -->
			<!-- <button class="btn-blue-light" @click="setLeadStatus(tableState.selectedRows)">
				{{ selectedRowsSold() ? 'Unmark' : 'Mark' }} as Sold
			</button>
			<div class="divider"></div> -->

			<button class="btn-white mx-4" @click="exportDetailsToCSV()">
				CSV
			</button>

			<button class="btn-white mx-4" @click="downloadPDF()">
				PDF
			</button>

			<div class="divider"></div>

			<button class="btn-blue-light" @click="printDetails()">
				<img id="printer" alt="printer icon" src="@/assets/printer.svg">
				Print All
			</button>
		</div>
		<LeadDetails v-if="state.leadsDetails.display" :leadData="leadData" @leadstatusupdate="updateLeadStatus" @leadburnupdate="updateBurnStatus"></LeadDetails>
	</div>
</template>

<style scoped>
._status {
	max-width: 150px;
}
thead tr {
	align-items: center;
}
th:not(.checkbox-wrapper),
td:not(.checkbox-wrapper) {
	padding: 10px;
}
</style>
<style>

#no-leads {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-top: 20vh;

	color: #C56327;

}

#no-leads span {
	line-height: 24px;
}

#no-leads img {
	margin-right: 12px;
}

#no-leads .link-text {
	text-decoration: underline;
	margin: 0 6px;

	cursor: pointer;
}
._name {
	cursor: pointer;
	color: #2a6bb8;

	&:hover {
		text-decoration: underline;
	}
}
.footer {
	display: flex;
	align-items: center;
	border-top: 1px solid #B0CCEE;
	background: white;
	position: fixed;
	bottom: 0;
	height: 60px;
	max-height: 60px;
	width: calc(100vw - 72px);
	padding: 0 36px;

}

.divider {
	border-left: 1px solid #B0CCEE;
	margin: 0 24px;
	height: 24px;
}

.selected-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	flex: 1;
	
	height: 100%;

	color: #2A6BB8;

}

.selected-count {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.selected-text {
	font-size: 15px;
	line-height: 17px;
	font-weight: 400;
}

#printer {
	filter: invert(1) grayscale(1) brightness(2);
	margin-right: 8px;
}

/* Table Styles */
table {
	width: 100%;

	border-collapse: collapse;
}

thead {
	width: 100%;
}

thead tr {
	font-size: 12px;
	line-height: 16px;
	color: #2A6BB8;

	background: white;
}

tbody tr {
	font-size: 16px;
	line-height: 24px;

	height: 48px;
}

tbody tr:hover:not(.selected) {
	background: white;
}



tr {
	width: 100%;
	border-bottom: 1px solid #B0CCEE;

	display: flex;
}
tr.-span-full {
	& > th,
	& > td {
		width: 100%;
		padding: 0 22px;
	}
	& > th {
		height: 35px;

		&.-clickable {
			cursor: pointer;
		}
	}
}

th,
td {
	width: 20%;
	text-align: left;
	display: flex;
	align-items: center;
}

th {
	height: 52px;

}
.v-th div {
	display: flex;
	align-items: center;
}

.vt-selected {
	background-color: #F4C9AC !important;
	border-color: #EBA675 !important;
}

.checkbox-wrapper {
	max-width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

input[type=checkbox] {
	width: 18px;
	height: 18px;

	/* https://stackoverflow.com/questions/2460501/how-to-change-checkboxs-border-style-in-css */
	border-color: rgba(0, 0, 0, 0.16);
}

input[type=checkbox]:checked {
	background-color: rgba(0, 0, 0, 0.54);
}

.vt-pagination .pagination {
	list-style-type: none;
	padding: 0;
	margin: 10px auto 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	& li {
		margin: 3px;
		border: 1px solid #ccc;
		border-radius: 3px;
		background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.2) 100%);
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,1) 100%);
		}

		&.disabled {
			opacity: 0.5;
		}
		&.active {
			background: linear-gradient(to top, #fff 0%, rgba(255,255,255,0.2) 100%);
			& a {
				font-weight: 600;
				color: var(--btn-blue-dark);
			}
		}
	}

	& a {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #4d4d4d;
		text-decoration: none;
		padding: 5px 6px;
	}
	& svg {
		transform: scale(0.75);
	}
}

/* END Table Style */
td._status {
	max-width: 150px;

    & span {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        padding: 6px 10px;
        border-radius: 3px;
    }

    &.-sold span {
        background-color: var(--btn-blue-light);
    }
    &.-pending span {
        background-color: var(--color-orange);
    }
    &.-new span {
        background-color: var(--color-green);
    }
}
.sold-wrapper {
	/* width: 43px;
  height: 24px; */
	background: #B0CCEE;
	border: 1px solid #7CABE3;
	border-radius: 4px;

	padding: 4px 6px;
	margin-right: 8px;

	font-size: 12px;
	line-height: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
}
</style>